import React from 'react';

import DefaultPage from '../DefaultPage/index';
import { StyledSkillsSection } from './styles';

export default function SkillsPage(props) {
  const content = (
    <>
      <StyledSkillsSection>
        <div>Languages</div>
        <div>
          <i className="devicon-java-plain colored">
            <span>Java</span>
          </i>
          <i className="devicon-javascript-plain colored">
            <span>Javascript</span>
          </i>
          <i className="devicon-html5-plain-wordmark colored">
            <span>HTML</span>
          </i>
          <i className="devicon-css3-plain-wordmark colored">
            <span>CSS</span>
          </i>
          <i className="devicon-c-plain colored">
            <span>C</span>
          </i>
          <i className="devicon-swift-plain colored">
            <span>Swift</span>
          </i>
          <i className="devicon-python-plain colored">
            <span>Python</span>
          </i>
        </div>
      </StyledSkillsSection>
      <StyledSkillsSection>
        <div>Frameworks and Tools</div>
        <div>
          <i className="devicon-nodejs-plain colored">
            <span>Node.js</span>
          </i>
          <i className="devicon-react-original colored">
            <span>React</span>
          </i>
          <i className="icon-redux">
            <span>Redux</span>
          </i>
          <i className="devicon-git-plain colored">
            <span>Git</span>
          </i>
        </div>
      </StyledSkillsSection>
    </>
  );

  return (
    <>
      <DefaultPage content={content} />
    </>
  );
}
