import React from 'react';

import DefaultPage from '../DefaultPage/index';
import ExperienceSection from './ExperienceSection';
import { StyledExperienceContainer } from './styles';

export default function ExperiencePage(props) {
  const content = (
    <StyledExperienceContainer>
      <ExperienceSection
        imgSource={'./assets/img/sea.jpg'}
        alt={'sea'}
        companyName={'Sea'}
        companyDescription={'Software Engineering Intern'}
        dateLocation={'May 2019 - Aug 2019 | Singapore, SG'}
        writeup={
          <>
            <li>Worked on SeaTalk Web in Sea’s Labs Division, SeaTalk team.</li>
            <li>
              Optimizing web and desktop app by caching to prevent excessive
              re-rendering.
            </li>
            <li>
              Created a standalone React-Redux web app for mock events
              management.
            </li>
          </>
        }
      />
      <ExperienceSection
        imgSource={'./assets/img/nus.jpg'}
        alt={'nus'}
        companyName={'National University of Singapore'}
        companyDescription={'Teaching Assistant, Computer Architecture'}
        dateLocation={'Aug 2018 - Dec 2018 | Singapore, SG'}
        writeup={
          <>
            Teaching assistant for lab classes dealing with:
            <li>Combinatorial and sequential circuit design techniques</li>
            <li>Processor datapath and control (execution cycles)</li>
            <li>Pipelining and cache</li>
          </>
        }
      />
      <ExperienceSection
        imgSource={'./assets/img/spark.jpg'}
        alt={'spark'}
        companyName={'Spark Systems Pte Ltd'}
        companyDescription={'Software Engineering Intern'}
        dateLocation={'May 2018 - Aug 2018 | Singapore, SG'}
        writeup={
          <>
            <li>
              Created a mailer system with Amazon SES and JavaMail to automate
              creation and sending of files to both external and internal
              clients.
            </li>
            <li>
              Implemented automation suite for regression tests on GUI using
              TextFX.
            </li>
          </>
        }
      />
      <ExperienceSection
        imgSource={'./assets/img/moh.svg'}
        alt={'moh'}
        companyName={'Ministry of Health'}
        companyDescription={'Intern, Non-Communicable Diseases Division'}
        dateLocation={'Jan 2016 - Jul 2016 | Singapore, SG'}
        writeup={
          <>
            <li>
              Planning of NurtureSG’s data collection phase comprising of focus
              group discussions
            </li>
            <li>
              Researched into healthy programmes, initiatives in other countries
              to compile good practices to use in Singapore.
            </li>
          </>
        }
      />
    </StyledExperienceContainer>
  );
  return (
    <>
      <DefaultPage content={content} />
    </>
  );
}
