import styled from 'styled-components';

export const StyledFooter = styled.footer`
  text-align: center;
  margin: 10px;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: 1.1s ease-in 0s 1 fadeIn;
`;
