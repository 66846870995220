import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { slide as Menu } from 'react-burger-menu';
import SideNavButton from './SideNavButton';
import SideNavA from './SideNavA';
import SideNavLink from './SideNavLink';
import { GITHUB_LINK, LINKEDIN_LINK } from '../common/constants';

import './index.css';

export default function SideNav(props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <SideNavButton onClick={() => setOpen(!isOpen)} isOpen={isOpen} />
      <Menu
        right
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={isOpen}
        width={'100%'}
      >
        <SideNavLink content="Home" link="/" />
        <SideNavLink content="Skills" link="/skills" />
        <SideNavLink content="Experience" link="/experience" />
        <SideNavLink content="Projects" link="/projects" />
        <SideNavLink content="Activities" link="/activities" />
        <SideNavA content="Resume" link="resume.pdf" />
        <SideNavA
          content={<i className="devicon-github-plain colored" />}
          link={GITHUB_LINK}
        />
        <SideNavA
          content={<i className="icon-linkedin" />}
          link={LINKEDIN_LINK}
        />
      </Menu>
    </>
  );
}

SideNav.propTypes = {
  list: PropTypes.node
};

SideNav.defaultProps = {
  list: []
};
