import { StyledLink, StyledA, StyledTab } from '../common/styles';
import styled from 'styled-components';

export * from '../common/styles';

export const StyledSideNavTab = styled(StyledTab)`
  margin: 0.3em 0;
`;

export const StyledSideNavLink = styled(StyledLink)`
  font-size: 0.8em;
  padding: 0;
`;

export const StyledSideNavA = styled(StyledA)`
  font-size: 0.8em;
  padding: 0;
`;
