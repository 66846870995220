import React from 'react';
import { StyledIFrame } from './styles';

export default ({ youtubeId }) => {
  return (
    <>
      <StyledIFrame
        allowFullScreen={true}
        src={`https://www.youtube.com/embed/${youtubeId}?rel=0&modestbranding=1`}
        frameBorder="0"
      />
    </>
  );
};
