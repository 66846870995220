import React from 'react';

import { StyledFloatingBar } from './styles';
import Floater from './Floater';
import { GITHUB_LINK, LINKEDIN_LINK } from '../common/constants';

export default function FloatingBar(props) {
  return (
    <StyledFloatingBar>
      <Floater
        content={<i className="devicon-github-plain colored" />}
        link={GITHUB_LINK}
      />
      <Floater content={<i className="icon-linkedin" />} link={LINKEDIN_LINK} />
    </StyledFloatingBar>
  );
}
