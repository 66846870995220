import React from 'react';

import DefaultPage from '../DefaultPage/index';
import ProjectSection from './ProjectSection';
import { StyledProjectContainer } from './styles';

export default function ProjectsPage(props) {
  const content = (
    <StyledProjectContainer>
      <ProjectSection
        projectName={'EzCard'}
        writeup={
          <>
            <li>
              An iOS app for quick exchange and updating of contact information.
            </li>
            <li>
              Implemented model and logic for CRUD in app, implemented search
              functionality.
            </li>
            <li>
              Made using <strong>Swift</strong> and <strong>Firebase</strong>.
            </li>
          </>
        }
      />
      <ProjectSection
        projectName={'AOS'}
        writeup={
          <>
            <li>
              Implemented shell on skeleton microkernel-based Barrelfish OS on
              an ARMv7 core PandaBoard ES using capabilities for physical and
              virtual address management, spawning of processes and IPCs.
            </li>
            <li>
              Implementation included an RPC handler for message passing of
              keyboard commands to shell process from main init process.
            </li>
          </>
        }
      />
      <ProjectSection
        projectName={'TheTracker'}
        writeup={
          <>
            <li>
              An address book to keep track of friends, students and the modules
              and events they are in.
            </li>
            <li>
              Implemented deep and shallow copies of objects for proper object
              model update and references.
            </li>
            <li>
              Made using <strong>Java</strong> and <strong>JavaFX</strong>.
            </li>
          </>
        }
      />
    </StyledProjectContainer>
  );
  return (
    <>
      <DefaultPage content={content} />
    </>
  );
}
