import React from 'react';

import { StyledSideNavTab, StyledSideNavA } from './styles';
import PropTypes from 'prop-types';

export default function SideNavA(props) {
  return (
    <StyledSideNavTab>
      <StyledSideNavA href={props.link} target="_blank">
        {props.content}
      </StyledSideNavA>
    </StyledSideNavTab>
  );
}

SideNavA.propTypes = {
  content: PropTypes.node,
  link: PropTypes.string
};
