import styled from 'styled-components';

export const StyledIFrame = styled.iframe`
  margin: 20px 0;
  width: 50vw;
  height: 28.125vw;

  @media only screen and (max-width: 900px) {
    width: 90vw;
    height: 50.625vw;
  }
`;
