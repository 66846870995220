import React from 'react';
import { StyledFooter } from './styles';

export default function Footer(props) {
  return (
    <StyledFooter>
      Made with <i className="icon-heart" /> by alistair.
    </StyledFooter>
  );
}
