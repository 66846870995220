import styled from 'styled-components';
import {
  StyledSectionContainer,
  StyledImg,
  StyledGridContainer
} from '../common/styles';
export * from '../common/styles';

export const StyledExperienceContainer = styled(StyledGridContainer)``;

export const StyledExperienceSection = styled(StyledSectionContainer)`
  div {
    padding: 0;
  }
`;

export const StyledSmallImg = styled(StyledImg)`
  width: 100px;
  height: 100px;
  border: none;
  border-radius: 0;
  object-fit: contain;

  /* Medium Devices, Desktops */
  @media only screen and (max-width: 992px), screen and (max-height: 800px) {
    width: 100px;
    height: 100px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width: 768px) {
    width: 75px;
    height: 75px;
  }

  /* Extra Small Devices, Phones */
  @media only screen and (max-width: 480px), screen and (max-height: 400px) {
    width: 50px;
    height: 50px;
  }

  /* Custom, iPhone Retina */
  @media only screen and (max-width: 320px) {
    width: 25px;
    height: 25px;
  }
`;

export const StyledCompanyTitle = styled.div``;

export const StyledCompanyName = styled.div`
  margin-left: 20px;
  width: 100%;
  display: flex;
`;

export const StyledCompanyDescription = styled.div`
  font-size: 1.15em;
  font-weight: 600;
  color: var(--primary_black);
`;

export const StyledInternshipDate = styled.div`
  font-size: 0.8em;
  font-weight: 400;
  color: var(--primary_black);
`;

export const StyledWriteup = styled.ul`
  margin-top: 0.9em;
  color: var(--secondary_black);
  padding: 0;
`;
