import React from 'react';

import { StyledTab, StyledNavBarLink } from './styles';
import PropTypes from 'prop-types';

export default function NavTabLink(props) {
  return (
    <StyledTab>
      <StyledNavBarLink exact to={props.link}>
        {props.text}
      </StyledNavBarLink>
    </StyledTab>
  );
}

NavTabLink.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string
};

NavTabLink.defaultProps = {
  link: '',
  text: ''
};
