import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledProjectSection,
  StyledProjectTitle,
  StyledProjectName,
  StyledProjectDescription,
  StyledWriteup
} from './styles';

export default function ProjectSection(props) {
  return (
    <StyledProjectSection>
      <StyledProjectTitle>
        <StyledProjectName>{props.projectName}</StyledProjectName>
      </StyledProjectTitle>
      <StyledProjectDescription>
        {props.projectDescription}
      </StyledProjectDescription>
      <StyledWriteup>{props.writeup}</StyledWriteup>
    </StyledProjectSection>
  );
}

ProjectSection.propTypes = {
  imgSource: PropTypes.node,
  alt: PropTypes.node,
  projectName: PropTypes.node,
  projectDescription: PropTypes.node,
  dateLocation: PropTypes.node,
  writeup: PropTypes.node
};
