import styled from 'styled-components';
import { StyledSectionContainer, StyledImg } from '../common/styles';
export * from '../common/styles';

export const StyledHomeSection = styled(StyledSectionContainer)`
  div {
    justify-content: space-around;
  }

  @media only screen and (max-width: 900px) {
    margin: 2em 5em;
    padding: 0;
  }
`;

export const StyledLargeImg = styled(StyledImg)`
  width: 400px;
  height: 400px;

  /* Medium Devices, Desktops */
  @media only screen and (max-width: 992px), screen and (max-height: 800px) {
    width: 400px;
    height: 400px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width: 768px) {
    width: 300px;
    height: 300px;
  }

  /* Extra Small Devices, Phones */
  @media only screen and (max-width: 480px), screen and (max-height: 400px) {
    width: 200px;
    height: 200px;
  }

  /* Custom, iPhone Retina */
  @media only screen and (max-width: 320px) {
    width: 100px;
    height: 100px;
  }
`;

export const StyledHomeWriteup = styled.div`
  text-align: center;

  @media only screen and (min-width: 900px) {
    margin: 0 20%;
  }
`;
