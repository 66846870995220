import React from 'react';

import { StyledNavigationBar, StyledList } from './styles';
import NavTabLink from './NavTabLink';
import NavTabA from './NavTabA';

export default function NavigationBar(props) {
  return (
    <StyledNavigationBar role="navigation">
      <StyledList>
        <NavTabLink text="Home" link="/" />
        <NavTabLink text="Skills" link="/skills" />
        <NavTabLink text="Experience" link="/experience" />
        <NavTabLink text="Projects" link="/projects" />
        <NavTabLink text="Activities" link="/activities" />
        <NavTabA text="Resume" link="resume.pdf" />
      </StyledList>
    </StyledNavigationBar>
  );
}
