import React from 'react';

import DefaultPage from '../DefaultPage/index';
import { StyledHomeSection, StyledLargeImg, StyledHomeWriteup } from './styles';

export default function HomePage(props) {
  const content = (
    <StyledHomeSection>
      <div>
        <StyledLargeImg src={'./assets/img/self.jpg'} alt={'self'} />
      </div>
      <StyledHomeWriteup>
        Hello! I am Alistair and I am a Software Engineer at ByteDance.
        I do a bit of fingerstyle guitar on the side, do check it out on the
        activities page!
      </StyledHomeWriteup>
    </StyledHomeSection>
  );

  return (
    <>
      <DefaultPage content={content} />
    </>
  );
}
