import styled from 'styled-components';
import { StyledSectionContainer } from '../common/styles';
export * from '../common/styles';

export const StyledSkillsSection = styled(StyledSectionContainer)`
  width: 80%;
  margin: 2em 5em;

  div {
    padding: 1.2em 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  div i {
    display: inline-grid;
    text-align: center;
    font-size: 3em;
    padding: 0.2rem 0.2rem 0.5rem;
    width: 70px;
  }

  div i:hover {
    span {
      opacity: 1;
      transition: 0.5s;
    }
  }

  div i span {
    font-family: 'Europa', 'Avenir', 'Century Gothic', sans-serif;
    color: #333;
    font-size: 14px;
    opacity: 0;
    padding-top: 0.5em;
  }
`;
