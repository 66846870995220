import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledExperienceSection,
  StyledCompanyTitle,
  StyledSmallImg,
  StyledCompanyName,
  StyledCompanyDescription,
  StyledInternshipDate,
  StyledWriteup
} from './styles';

export default function ExperienceSection(props) {
  return (
    <StyledExperienceSection>
      <StyledCompanyTitle>
        <StyledSmallImg src={props.imgSource} alt={props.alt} />
        <StyledCompanyName>{props.companyName}</StyledCompanyName>
      </StyledCompanyTitle>
      <StyledCompanyDescription>
        {props.companyDescription}
      </StyledCompanyDescription>
      <StyledInternshipDate>{props.dateLocation}</StyledInternshipDate>
      <StyledWriteup>{props.writeup}</StyledWriteup>
    </StyledExperienceSection>
  );
}

ExperienceSection.propTypes = {
  imgSource: PropTypes.node,
  alt: PropTypes.node,
  companyName: PropTypes.node,
  companyDescription: PropTypes.node,
  dateLocation: PropTypes.node,
  writeup: PropTypes.node
};
