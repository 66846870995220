import React from 'react';

import { StyledSideNavTab, StyledSideNavLink } from './styles';
import PropTypes from 'prop-types';

export default function SideNavLink(props) {
  return (
    <StyledSideNavTab>
      <StyledSideNavLink exact to={props.link}>
        {props.content}
      </StyledSideNavLink>
    </StyledSideNavTab>
  );
}

SideNavLink.propTypes = {
  link: PropTypes.string,
  content: PropTypes.string
};

SideNavLink.defaultProps = {
  link: '',
  content: ''
};
