import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import HomePage from './components/HomePage/index';
import SkillsPage from './components/SkillsPage/index';
import ExperiencePage from './components/ExperiencePage/index';
import ProjectsPage from './components/ProjectsPage/index';
import ActivitiesPage from './components/ActivitiesPage/index';

function App() {
  return (
    <>
      <HashRouter>
          <Route path="/" exact={true} component={HomePage} />
          <Route path="/skills" component={SkillsPage} />
          <Route path="/experience" component={ExperiencePage} />
          <Route path="/projects" component={ProjectsPage} />
          <Route path="/activities" component={ActivitiesPage} />
      </HashRouter>
    </>
  );
}

export default App;
