import React from 'react';

import { StyledTab, StyledNavBarA } from './styles';
import PropTypes from 'prop-types';

export default function NavTabA(props) {
  return (
    <StyledTab>
      <StyledNavBarA href={props.link} target="_blank">
        {props.text}
      </StyledNavBarA>
    </StyledTab>
  );
}

NavTabA.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string
};
