import styled from 'styled-components';
import {
  StyledSectionContainer,
  StyledImg,
  StyledGridContainer
} from '../common/styles';
export * from '../common/styles';

export const StyledProjectContainer = styled(StyledGridContainer)`
  @media only screen and (min-width: 900px) {
    margin-top: 2em;
    display: grid;
    grid-template-columns: auto auto auto;
  }
`;

export const StyledProjectSection = styled(StyledSectionContainer)`
  div {
    padding: 0;
  }
`;

export const StyledSmallImg = styled(StyledImg)`
  width: 100px;
  height: 100px;
  border: none;
  border-radius: 0;
  object-fit: contain;

  /* Medium Devices, Desktops */
  @media only screen and (max-width: 992px), screen and (max-height: 800px) {
    width: 100px;
    height: 100px;
  }

  /* Small Devices, Tablets */
  @media only screen and (max-width: 768px) {
    width: 75px;
    height: 75px;
  }

  /* Extra Small Devices, Phones */
  @media only screen and (max-width: 480px), screen and (max-height: 400px) {
    width: 50px;
    height: 50px;
  }

  /* Custom, iPhone Retina */
  @media only screen and (max-width: 320px) {
    width: 25px;
    height: 25px;
  }
`;

export const StyledProjectTitle = styled.div``;

export const StyledProjectName = styled.div`
  width: 100%;
  font-weight: 600;
`;

export const StyledProjectDescription = styled.div`
  font-size: 1.15em;
  font-weight: 600;
  color: var(--primary_black);
`;

export const StyledWriteup = styled.ul`
  margin-top: 0.9em;
  color: var(--secondary_black);
  padding: 0;
`;
