import React from 'react';

import DefaultPage from '../DefaultPage/index';
import YoutubeVideo from '../YoutubeVideo/index';

export default function ActivitiesPage(props) {
  const content = (
    <>
      <YoutubeVideo youtubeId={'b2PVj1ZzdT4'} />
      <YoutubeVideo youtubeId={'ILytK54KCVk'} />
    </>
  );

  return (
    <>
      <DefaultPage content={content} />
    </>
  );
}
