import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSideNavButton = styled.div`
  * {
    margin: 0;
    padding: 0;
  }

  /* Icon 1 */

   {
    -webkit-tap-highlight-color: transparent;
    z-index: 2000;
    width: 40px;
    height: 28px;
    position: fixed;
    right: 30px;
    bottom: 20px;
    margin-bottom: 20px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  span {
    display: block;
    position: absolute;
    height: 7px;
    width: 100%;
    background: var(--primary_yellow);
    border-radius: 7px;
    opacity: 1;
    // left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  ${props =>
    props.isOpen
      ? `span:nth-child(1) {
    top: 14px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  span:nth-child(2) {
    opacity: 0;
    // left: -60px;
  }

  span:nth-child(3) {
    top: 14px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }`
      : `span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2) {
    top: 14px;
  }

  span:nth-child(3) {
    top: 28px;
  }`}

  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

export default function SideNavButton(props) {
  return (
    <StyledSideNavButton isOpen={props.isOpen} onClick={props.onClick}>
      <span />
      <span />
      <span />
    </StyledSideNavButton>
  );
}

SideNavButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func
};

SideNavButton.defaultProps = {
  isOpen: false,
  onClick: undefined
};
