import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledVerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  color: var(--primary_black);

  @media only screen and (min-width: 900px) {
    padding-top: 3.5em;

    div {
      flex: 1 0 auto;
    }

    footer {
      flex-shrink: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: 1.1s ease-in 0s 1 fadeIn;
`;

export const StyledSectionContainer = styled.div`
  margin: 2em 5em;

  @media only screen and (max-width: 900px) {
    margin: 1em 2em;
    padding: 0;
  }

  div {
    padding: 1.2em 0;
    display: flex;
    align-items: center;
  }
`;

export const StyledGridContainer = styled.div`
  display: inline-grid;

  @media only screen and (min-width: 900px) {
    margin: -2em 2em 0;
    display: grid;
    grid-template-columns: auto auto;
  }
`;

export const StyledTab = styled.li`
  margin: 0.8em;
  list-style: none;
`;

export const StyledLink = styled(NavLink)`
  padding: 0.8em;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: var(--primary_red);
    transition: 0.2s;
  }

  &.active {
    color: var(--primary_yellow);
  }
`;

export const StyledA = styled.a`
  padding: 0.8em;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: var(--primary_red);
    transition: 0.2s;
  }

  &.active {
    color: var(--primary_yellow);
  }
`;

export const StyledImg = styled.img`
  border-radius: 50%;
  border: 4px solid var(--primary_yellow);
`;
