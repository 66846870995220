import React from 'react';

import { StyledFloater, StyledFloaterA } from './styles';
import PropTypes from 'prop-types';

export default function Floater(props) {
  return (
    <StyledFloater>
      <StyledFloaterA href={props.link} target="_blank">
        {props.content}
      </StyledFloaterA>
    </StyledFloater>
  );
}

Floater.propTypes = {
  content: PropTypes.node,
  link: PropTypes.string
};
