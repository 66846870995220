import React from 'react';
import PropTypes from 'prop-types';

import { StyledVerticalContainer } from '../common/styles';
import NavigationBar from '../NavigationBar/index';
import SideNav from '../SideNav/index';
import Footer from '../Footer/Footer';
import FloatingBar from '../FloatingBar';

export default function DefaultPage(props) {
  return (
    <>
      <SideNav />
      <NavigationBar />
      <FloatingBar />
      <StyledVerticalContainer>
        {props.content}
        <Footer />
      </StyledVerticalContainer>
    </>
  );
}

DefaultPage.propTypes = {
  content: PropTypes.node
};

DefaultPage.defaultProps = {
  content: <div />
};
