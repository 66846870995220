import styled from 'styled-components';
import { StyledLink, StyledA } from '../common/styles';
export * from '../common/styles';

export const StyledNavigationBar = styled.nav`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 1.1em;
  padding: 1em 0;
  background: transparent
    linear-gradient(to top, rgba(255, 255, 255, 0.9), #fff);
  box-shadow: 0px 8px 6px -6px rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: bold;
  color: var(--secondary_gray);

  @media only screen and (max-width: 900px) {
    display: none;
  }

  :hover {
    color: var(--secondary_black);
  }
`;

export const StyledList = styled.ul`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 15vw;
  list-style: none;
`;

export const StyledNavBarLink = styled(StyledLink)`
  &:hover {
    border-bottom: 4px solid var(--primary_red);
  }

  &.active {
    border-bottom: 4px solid var(--primary_yellow);
  }
`;

export const StyledNavBarA = styled(StyledA)`
  &:hover {
    border-bottom: 4px solid var(--primary_red);
  }

  &.active {
    border-bottom: 4px solid var(--primary_yellow);
  }
`;
