import styled from 'styled-components';
import { StyledA } from '../common/styles';

export const StyledFloatingBar = styled.div`
  display: grid;
  position: fixed;
  right: 40px;
  top: 6em;
  border: 2px solid var(--secondary_black);
  border-radius: 40px;

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const StyledFloater = styled.div`
  margin: 5px 0;

  :first-child {
    margin-top: 15px;
  }

  :last-child {
    margin-bottom: 15px;
  }

  :hover {
    transform: scale(1.4);
  }

  transition: transform 0.3s;
`;

export const StyledFloaterA = styled(StyledA)`
  font-size: 1.4em;
  padding: 0 0.5em;
`;
